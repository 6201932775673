import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import "./StressTest.css";
import guide from "../../assets/dataGuide.png";
import uploadIcon from "../../assets/uploadIcon.svg";

export default function StressTest() {
  const [isDevelopment, setIsDevelopment] = useState(true);

  return (
    <>
      <Navbar />
      <div className="stresstest-page-container">
        {isDevelopment ? (
          <div className="development-message">Coming soon</div>
        ) : (
          <div className="stresstest-content-container">
            <div className="stresstest-payment-section">
              <div className="stresstest-card stresstest-payment-card">
                <img
                  src={guide}
                  alt="Upload-guide"
                  className="stresstest-image"
                />
              </div>
            </div>
            <div className="stresstest-card stresstest-upload-card">
              <h2 className="stresstest-upload-title">Upload your data</h2>
              <p className="stresstest-upload-subtitle">Upload XLS file...</p>
              <div className="stresstest-upload-box">
                <img
                  src={uploadIcon}
                  alt="Upload Icon"
                  className="stresstest-upload-icon"
                />
                <p className="stresstest-upload-text">
                  Drag and Drop files here
                </p>
                <p className="stresstest-upload-or">-OR-</p>
                <button className="stresstest-btn stresstest-browse-btn">
                  Browse files
                </button>
              </div>
              <button className="stresstest-btn stresstest-upload-btn">
                Upload
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
