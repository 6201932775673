// import React, { useState, useContext, useEffect, useRef } from "react";
// import "./MyList.css";
// import refreshImage from "../../assets/refresh-line.svg";
// import { ScripContext } from "../context/ScripContext";
// import { useNavigate } from "react-router-dom";
// import { useSnackbar } from "notistack"; // Import useSnackbar from notistack
// import { TailSpin } from "react-loader-spinner"; // Import TailSpin loader

// const MyList = ({ selectedHistory, selectedForecast }) => {
//   const {
//     scrips,
//     quantities,
//     setQuantities,
//     closingPrices,
//     setScrips,
//     setSelectedIndices,
//     setClosingPrices,
//   } = useContext(ScripContext);
//   const navigate = useNavigate();
//   const { enqueueSnackbar } = useSnackbar(); // Initialize the snackbar

//   const token = localStorage.getItem("token");
//   const email = localStorage.getItem("user_email");

//   const [showCard, setShowCard] = useState(false);
//   const [addFunds, setAddFunds] = useState("");
//   const [withdrawFunds, setWithdrawFunds] = useState("");
//   const [currentHoldingValue, setCurrentHoldingValue] = useState(0);
//   const [withdrawDisabled, setWithdrawDisabled] = useState(true);
//   const [addFundsRequired, setAddFundsRequired] = useState(false);
//   const [loading, setLoading] = useState(false); // Loading state
//   const [nextDisabled, setNextDisabled] = useState(true); // Next button disabled state
//   const [addFundsPlaceholder, setAddFundsPlaceholder] = useState("");
//   const [withdrawFundsPlaceholder, setWithdrawFundsPlaceholder] = useState("");
//   const [unitPlaceholders, setUnitPlaceholders] = useState(Array(12).fill(""));
//   const [verifyChecked, setVerifyChecked] = useState(false); // State for verify checkbox

//   // Create refs for each input field
//   const inputRefs = useRef([]);

//   const handleInputChange = (index, event) => {
//     let value = event.target.value;

//     // Truncate the value to the first 6 digits if it exceeds the limit
//     if (value.length > 6) {
//       value = value.slice(0, 6);
//       enqueueSnackbar("Maximum allowed units are 6 digits (999999)", {
//         variant: "warning",
//         anchorOrigin: { vertical: "top", horizontal: "left" },
//       });
//     }

//     // Allow zero or positive integer values
//     if (value !== "") {
//       value = parseInt(value, 10); // Convert to integer
//       if (isNaN(value) || value < 0) {
//         value = null; // Ensure only valid numbers are set, prevent negatives
//       }
//     } else {
//       value = null; // Treat empty input as null
//     }

//     const newQuantities = [...quantities];
//     newQuantities[index] = value;
//     setQuantities(newQuantities);

//     // Reset Add Funds and Withdraw Funds fields
//     setAddFunds("");
//     setWithdrawFunds("");

//     // Revalidate Add Funds and Withdraw Funds fields
//     checkWithdrawDisabled();
//     checkAddFundsRequired();
//   };

//   const handleInputValidation = (event) => {
//     const value = event.target.value;
//     event.target.value = value.replace(/[^0-9]/g, "");
//   };

//   const handleKeyDown = (index, event) => {
//     if (event.key === "Enter") {
//       event.preventDefault();
//       if (index < scrips.length - 1) {
//         inputRefs.current[index + 1]?.focus();
//       }
//     }
//   };

//   const handleResetQuantities = () => {
//     setQuantities(Array(15).fill(null));
//   };

//   const calculateCurrentHoldingValue = () => {
//     let value = 0;
//     quantities.forEach((qty, index) => {
//       if (qty !== null && closingPrices[index]) {
//         value += qty * closingPrices[index];
//       }
//     });
//     value = Math.round(value * 10) / 10; // Round to one decimal place
//     setCurrentHoldingValue(value);
//   };

//   useEffect(() => {
//     calculateCurrentHoldingValue();
//     checkWithdrawDisabled();
//     checkAddFundsRequired();
//     checkNextDisabled();
//   }, [quantities, closingPrices, scrips]);

//   useEffect(() => {
//     setAddFundsPlaceholder(" Minimum value is 5000.");
//     setWithdrawFundsPlaceholder(
//       `Up to ${Math.min(currentHoldingValue * 0.5, 999999).toFixed(1)}`
//     );
//   }, [currentHoldingValue, quantities]);

//   useEffect(() => {
//     const newUnitPlaceholders = scrips.map((scrip, index) =>
//       scrip && scrip !== "Scrip Name" ? "Enter quantity" : ""
//     );
//     setUnitPlaceholders(newUnitPlaceholders);
//   }, [scrips]);

//   const checkWithdrawDisabled = () => {
//     const allUnitsEntered = scrips.every(
//       (scrip, index) =>
//         !scrip || (quantities[index] !== null && quantities[index] !== 0)
//     );
//     setWithdrawDisabled(!allUnitsEntered);
//   };

//   const checkAddFundsRequired = () => {
//     const allUnitsEmpty = scrips.every(
//       (scrip, index) =>
//         !scrip || quantities[index] === null || quantities[index] === 0
//     );
//     setAddFundsRequired(allUnitsEmpty);
//   };

//   const checkNextDisabled = () => {
//     const atLeastOneScripPopulated = scrips.some(
//       (scrip) => scrip && scrip !== "Scrip Name"
//     );
//     setNextDisabled(!atLeastOneScripPopulated);
//   };

//   const resetAllFields = () => {
//     setScrips(Array(12).fill("Scrip Name"));
//     setQuantities(Array(12).fill(null));
//     setSelectedIndices(Array(12).fill(""));
//     setClosingPrices(Array(12).fill("-"));
//     setAddFunds("");
//     setWithdrawFunds("");
//     setCurrentHoldingValue(0);
//   };

//   const submit = async () => {
//     if (!verifyChecked) {
//       enqueueSnackbar("Please verify by checking the checkbox.", {
//         variant: "warning",
//         anchorOrigin: { vertical: "top", horizontal: "left" },
//       });
//       return;
//     }

//     if (addFundsRequired && (addFunds === "" || parseInt(addFunds, 10) === 0)) {
//       enqueueSnackbar(
//         "Add funds is required if no units are entered or all units are 0.",
//         {
//           variant: "warning",
//           anchorOrigin: { vertical: "top", horizontal: "left" },
//         }
//       );
//       return;
//     }

//     const addFundsValue = addFunds !== "" ? parseInt(addFunds, 10) : 0;
//     const withdrawFundsValue =
//       withdrawFunds !== "" ? parseInt(withdrawFunds, 10) : 0;

//     const data = {
//       email_id: email,
//       select_history: selectedHistory,
//       compute_duration: selectedForecast,
//       process_date: null,
//       process_status: 1,
//       report_generated: false,
//       report_id: null,
//       report_emailed: false,
//       add_funds: addFundsValue,
//       withdraw_funds: withdrawFundsValue,
//     };

//     scrips.forEach((scrip, index) => {
//       if (scrip) {
//         data[`scrip${index + 1}_symbol`] = scrip;
//         data[`scrip${index + 1}_closing_price`] = closingPrices[index];
//         data[`scrip${index + 1}_qty`] = quantities[index] || 0;
//       }
//     });

//     for (let i = scrips.length + 1; i <= 15; i++) {
//       data[`scrip${i}_symbol`] = null;
//       data[`scrip${i}_qty`] = null;
//       data[`scrip${i}_closing_price`] = null;
//     }

//     console.log("Submitting data:", data);

//     setLoading(true); // Start loading spinner
//     try {
//       const response = await fetch("https://api.antsanalyzer.com/submit-data", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(data),
//       });

//       if (response.ok) {
//         enqueueSnackbar("Data submitted successfully", {
//           variant: "success",
//           anchorOrigin: { vertical: "top", horizontal: "left" },
//         });
//         console.log("Data submitted successfully");
//         resetAllFields();
//         navigate("/subscription");
//       } else {
//         const responseData = await response.json();
//         const errorMessage = responseData.message || "Failed to submit data";

//         enqueueSnackbar(errorMessage, {
//           variant:
//             responseData.message === "You can only submit data once per day"
//               ? "info"
//               : "error",
//           anchorOrigin: { vertical: "top", horizontal: "left" },
//         });
//         console.error("Failed to submit data");
//       }
//     } catch (error) {
//       enqueueSnackbar("Error submitting data", {
//         variant: "error",
//         anchorOrigin: { vertical: "top", horizontal: "left" },
//       });
//       console.error("Error submitting data:", error);
//     } finally {
//       setLoading(false); // Stop loading spinner
//     }
//   };

//   const handleNextClick = () => {
//     setShowCard(true);
//   };

//   const handleCloseCard = () => {
//     setShowCard(false);
//   };

//   const handleAddFundsChange = (event) => {
//     const value = event.target.value.replace(/[^0-9]/g, "");
//     if (value.length <= 7) {
//       setAddFunds(value);
//     }
//     if (value !== "") {
//       setWithdrawFunds("");
//     }
//   };

//   const handleWithdrawFundsChange = (event) => {
//     const value = event.target.value.replace(/[^0-9]/g, "");
//     setWithdrawFunds(value);
//     if (value !== "") {
//       setAddFunds("");
//     }
//   };

//   const handleAddFundsBlur = () => {
//     if (addFunds !== "" && parseInt(addFunds, 10) < 5000) {
//       enqueueSnackbar(
//         "Minimum add funds value is 5000. Anything less will be considered as 0.",
//         {
//           variant: "warning",
//           anchorOrigin: { vertical: "top", horizontal: "left" },
//         }
//       );
//       setAddFunds("");
//     }
//   };

//   const handleWithdrawFundsBlur = () => {
//     const maxWithdraw = currentHoldingValue * 0.5;
//     const minWithdraw = 1000;
//     const withdrawValue = parseInt(withdrawFunds, 10);

//     if (withdrawFunds !== "" && withdrawValue > maxWithdraw) {
//       enqueueSnackbar(
//         `Maximum withdrawable amount is 50% of current holding value (${maxWithdraw.toFixed(
//           2
//         )}).`,
//         {
//           variant: "warning",
//           anchorOrigin: { vertical: "top", horizontal: "left" },
//         }
//       );
//       setWithdrawFunds(maxWithdraw.toFixed(1));
//     } else if (withdrawFunds !== "" && withdrawValue < minWithdraw) {
//       enqueueSnackbar(
//         `Minimum withdrawable amount is ${minWithdraw}. Anything less will be considered as 0.`,
//         {
//           variant: "warning",
//           anchorOrigin: { vertical: "top", horizontal: "left" },
//         }
//       );
//       setWithdrawFunds("");
//     }
//   };

//   useEffect(() => {
//     console.log("Closing Prices: ", closingPrices);
//   }, [closingPrices]);

//   return (
//     <div className="mylist">
//       <div className="mylist-header">
//         <h2 className="mylist-h2">My List</h2>
//         <img
//           src={refreshImage}
//           alt="Refresh"
//           className="mylist-refresh-icon"
//           onClick={handleResetQuantities}
//         />
//       </div>
//       <div className="mylist-table">
//         <table>
//           <thead>
//             <tr>
//               <th>Scrip Symbol</th>
//               <th>Units</th>
//             </tr>
//           </thead>
//           <tbody>
//             {scrips.map((scrip, index) => (
//               <tr key={index}>
//                 <td
//                   className={
//                     scrip === "Scrip Symbol" ? "mylist-default-scrip" : ""
//                   }
//                 >
//                   {scrip}
//                 </td>
//                 <td>
//                   <input
//                     type="text"
//                     value={quantities[index] !== null ? quantities[index] : ""}
//                     onChange={(event) => handleInputChange(index, event)}
//                     onInput={handleInputValidation}
//                     onKeyDown={(event) => handleKeyDown(index, event)}
//                     className={`mylist-unit-input ${
//                       !scrip ? "mylist-disabled-input" : ""
//                     }`}
//                     disabled={!scrip}
//                     placeholder={unitPlaceholders[index]}
//                     ref={(el) => (inputRefs.current[index] = el)} // Assign refs
//                   />
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="mylist-optimise-section">
//         <div className="mylist-optimise-heading">
//           <span>Optimise data for Analysis</span>
//         </div>
//         <div className="mylist-optimise-content">
//           <p className="mylist-description">
//             (Minimum Variance or Minimum loss when portfolio goes negative)
//           </p>
//           <div className="mylist-expected-return">
//             <input
//               type="checkbox"
//               id="goalCheckbox"
//               className="mylist-goal-checkbox"
//               checked
//               readOnly
//             />
//             <label
//               htmlFor="goalCheckbox"
//               className="mylist-span mylist-disabled-label"
//             >
//               Set Goal for minimum Variance
//             </label>
//           </div>
//         </div>
//         <hr className="mylist-divider" />
//         <button
//           className="mylist-next-button"
//           onClick={handleNextClick}
//           disabled={nextDisabled}
//         >
//           {loading && !showCard ? (
//             <TailSpin color="#00BFFF" height={20} width={20} />
//           ) : (
//             "NEXT"
//           )}
//         </button>
//       </div>
//       {showCard && (
//         <div className="mylist-card">
//           <div className="mylist-card-content">
//             <button className="mylist-close-button" onClick={handleCloseCard}>
//               ×
//             </button>
//             <h2 className="mylist-card-h2">Add/Withdraw Funds</h2>
//             <div className="mylist-input-container">
//               <div className="mylist-input-row">
//                 <label className="mylist-label">Current Holding Value</label>
//                 <input
//                   type="text"
//                   className="mylist-input"
//                   value={currentHoldingValue}
//                   readOnly
//                 />
//               </div>
//               <div className="mylist-input-row">
//                 <label className="mylist-label">Add Funds</label>
//                 <input
//                   type="text"
//                   className="mylist-input"
//                   value={addFunds}
//                   onChange={handleAddFundsChange}
//                   onBlur={handleAddFundsBlur}
//                   maxLength={7}
//                   placeholder={addFundsPlaceholder}
//                 />
//               </div>
//               <div className="mylist-input-row">
//                 <label className="mylist-label">Withdraw Funds</label>
//                 <input
//                   type="text"
//                   className="mylist-input"
//                   value={withdrawFunds}
//                   onChange={handleWithdrawFundsChange}
//                   onBlur={handleWithdrawFundsBlur}
//                   disabled={withdrawDisabled}
//                   placeholder={withdrawFundsPlaceholder}
//                 />
//               </div>
//               {withdrawDisabled && (
//                 <div className="mylist-warning-message">
//                   Withdraw is disabled as one or more scrips are empty.
//                 </div>
//               )}
//             </div>
//             <div className="mylist-notes-container">
//               <div className="mylist-notes-label">
//                 <strong>Note:</strong>
//               </div>
//               <div className="mylist-notes">
//                 <p className="point-one">
//                   1. For optimization (existing returns with optimized risk) No
//                   need to add Additional funds or Withdraw funds, leave as it is
//                   and submit.
//                 </p>
//                 <p className="point-two">
//                   2. If combination of Large cap, Mid cap and Small cap
//                   portfolios exists Additional Fund is distributed to minimize
//                   risk, Withdrawal is done without altering current proportion.
//                 </p>
//                 <p className="point-three">
//                   3. Minimum value that can be entered in Add funds/Withdraw
//                   funds is 1000. If both Add Funds/ Withdraw funds are entered,
//                   then withdraw is rolled back to “0” before computing.
//                 </p>
//               </div>
//             </div>
//             <div className="mylist-vartest">
//   <h2 className="mylist-vartest-h2">Var (Value at Risk) and Stress Test (Crash Test)</h2>
//   <div className="mylist-vartest-container">
//     <div className="mylist-vartest-checkbox-group">
//       <label>
//         <input type="radio" name="testOption" value="yes" />
//         Yes
//       </label>
//       <label>
//         <input type="radio" name="testOption" value="no" />
//         No
//       </label>
//     </div>
//     <p className="mylist-vartest-note">
//       Note: You have to make payment of 99/- for one test report
//     </p>
//   </div>
// </div>

//             <div className="mylist-checkbox-container">
//               <input
//                 type="checkbox"
//                 id="verifyCheckbox"
//                 className="mylist-verify-checkbox"
//                 checked={verifyChecked}
//                 onChange={() => setVerifyChecked(!verifyChecked)}
//               />
//               <label
//                 htmlFor="verifyCheckbox"
//                 className="mylist-verify-checkbox-label"
//               >
//                 Verify that you're not a robot
//               </label>
//             </div>
//             <div className="mylist-submit-button-container">
//               <button
//                 className="mylist-submit-button"
//                 onClick={submit}
//                 disabled={!verifyChecked}
//               >
//                 {loading && showCard ? (
//                   <TailSpin color="#00BFFF" height={20} width={20} />
//                 ) : (
//                   "SUBMIT"
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default MyList;

import React, { useState, useContext, useEffect, useRef } from "react";
import "./MyList.css";
import refreshImage from "../../assets/refresh-line.svg";
import { ScripContext } from "../context/ScripContext";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack"; // Import useSnackbar from notistack
import { TailSpin } from "react-loader-spinner"; // Import TailSpin loader

const MyList = ({ selectedHistory, selectedForecast }) => {
  const {
    scrips,
    quantities,
    setQuantities,
    closingPrices,
    setScrips,
    setSelectedIndices,
    setClosingPrices,
  } = useContext(ScripContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar(); // Initialize the snackbar

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("user_email");

  const [showCard, setShowCard] = useState(false);
  const [addFunds, setAddFunds] = useState("");
  const [withdrawFunds, setWithdrawFunds] = useState("");
  const [currentHoldingValue, setCurrentHoldingValue] = useState(0);
  const [withdrawDisabled, setWithdrawDisabled] = useState(true);
  const [addFundsRequired, setAddFundsRequired] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [nextDisabled, setNextDisabled] = useState(true); // Next button disabled state
  const [addFundsPlaceholder, setAddFundsPlaceholder] = useState("");
  const [withdrawFundsPlaceholder, setWithdrawFundsPlaceholder] = useState("");
  const [unitPlaceholders, setUnitPlaceholders] = useState(Array(12).fill(""));
  const [verifyChecked, setVerifyChecked] = useState(false); // State for verify checkbox
  const [varStatus, setVarStatus] = useState(1); // State for Var test

  // Create refs for each input field
  const inputRefs = useRef([]);

  const handleInputChange = (index, event) => {
    let value = event.target.value;

    // Truncate the value to the first 6 digits if it exceeds the limit
    if (value.length > 6) {
      value = value.slice(0, 6);
      enqueueSnackbar("Maximum allowed units are 6 digits (999999)", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
    }

    // Allow zero or positive integer values
    if (value !== "") {
      value = parseInt(value, 10); // Convert to integer
      if (isNaN(value) || value < 0) {
        value = null; // Ensure only valid numbers are set, prevent negatives
      }
    } else {
      value = null; // Treat empty input as null
    }

    const newQuantities = [...quantities];
    newQuantities[index] = value;
    setQuantities(newQuantities);

    // Reset Add Funds and Withdraw Funds fields
    setAddFunds("");
    setWithdrawFunds("");

    // Revalidate Add Funds and Withdraw Funds fields
    checkWithdrawDisabled();
    checkAddFundsRequired();
  };

  const handleInputValidation = (event) => {
    const value = event.target.value;
    event.target.value = value.replace(/[^0-9]/g, "");
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index < scrips.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleResetQuantities = () => {
    setQuantities(Array(15).fill(null));
  };

  const calculateCurrentHoldingValue = () => {
    let value = 0;
    quantities.forEach((qty, index) => {
      if (qty !== null && closingPrices[index]) {
        value += qty * closingPrices[index];
      }
    });
    value = Math.round(value * 10) / 10; // Round to one decimal place
    setCurrentHoldingValue(value);
  };

  useEffect(() => {
    calculateCurrentHoldingValue();
    checkWithdrawDisabled();
    checkAddFundsRequired();
    checkNextDisabled();
  }, [quantities, closingPrices, scrips]);

  useEffect(() => {
    setAddFundsPlaceholder(" Minimum value is 5000.");
    setWithdrawFundsPlaceholder(
      `Up to ${Math.min(currentHoldingValue * 0.5, 999999).toFixed(1)}`
    );
  }, [currentHoldingValue, quantities]);

  useEffect(() => {
    const newUnitPlaceholders = scrips.map((scrip, index) =>
      scrip && scrip !== "Scrip Name" ? "Enter quantity" : ""
    );
    setUnitPlaceholders(newUnitPlaceholders);
  }, [scrips]);

  const checkWithdrawDisabled = () => {
    const allUnitsEntered = scrips.every(
      (scrip, index) =>
        !scrip || (quantities[index] !== null && quantities[index] !== 0)
    );
    setWithdrawDisabled(!allUnitsEntered);
  };

  const checkAddFundsRequired = () => {
    const allUnitsEmpty = scrips.every(
      (scrip, index) =>
        !scrip || quantities[index] === null || quantities[index] === 0
    );
    setAddFundsRequired(allUnitsEmpty);
  };

  const checkNextDisabled = () => {
    const atLeastOneScripPopulated = scrips.some(
      (scrip) => scrip && scrip !== "Scrip Name"
    );
    setNextDisabled(!atLeastOneScripPopulated);
  };

  const resetAllFields = () => {
    setScrips(Array(12).fill("Scrip Name"));
    setQuantities(Array(12).fill(null));
    setSelectedIndices(Array(12).fill(""));
    setClosingPrices(Array(12).fill("-"));
    setAddFunds("");
    setWithdrawFunds("");
    setCurrentHoldingValue(0);
    setVarStatus(1); // Reset varStatus
  };

  const submit = async () => {
    if (!verifyChecked) {
      enqueueSnackbar("Please verify by checking the checkbox.", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
      return;
    }

    // if (varStatus === 1) {
    //   enqueueSnackbar("Please select Yes or No for Var test.", {
    //     variant: "warning",
    //     anchorOrigin: { vertical: "top", horizontal: "left" },
    //   });
    //   return;
    // }

    if (addFundsRequired && (addFunds === "" || parseInt(addFunds, 10) === 0)) {
      enqueueSnackbar(
        "Add funds is required if no units are entered or all units are 0.",
        {
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        }
      );
      return;
    }

    const addFundsValue = addFunds !== "" ? parseInt(addFunds, 10) : 0;
    const withdrawFundsValue =
      withdrawFunds !== "" ? parseInt(withdrawFunds, 10) : 0;

    const data = {
      email_id: email,
      select_history: selectedHistory,
      compute_duration: selectedForecast,
      process_date: null,
      process_status: 1,
      report_generated: false,
      report_id: null,
      report_emailed: false,
      add_funds: addFundsValue,
      withdraw_funds: withdrawFundsValue,
      var_status: varStatus, // Include varStatus in submission data
    };

    scrips.forEach((scrip, index) => {
      if (scrip) {
        data[`scrip${index + 1}_symbol`] = scrip;
        data[`scrip${index + 1}_closing_price`] = closingPrices[index];
        data[`scrip${index + 1}_qty`] = quantities[index] || 0;
      }
    });

    for (let i = scrips.length + 1; i <= 15; i++) {
      data[`scrip${i}_symbol`] = null;
      data[`scrip${i}_qty`] = null;
      data[`scrip${i}_closing_price`] = null;
    }

    console.log("Submitting data:", data);

    setLoading(true); // Start loading spinner
    try {
      const response = await fetch("https://api.antsanalyzer.com/submit-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        enqueueSnackbar("Data submitted successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
        console.log("Data submitted successfully");
        resetAllFields();

        // Navigate based on varStatus
        if (varStatus === 1) {
          navigate("/stresstestsubscription");
        } else {
          navigate("/optsubscription");
        }
      } else {
        const responseData = await response.json();
        const errorMessage = responseData.message || "Failed to submit data";

        enqueueSnackbar(errorMessage, {
          variant:
            responseData.message === "You can only submit data once per day"
              ? "info"
              : "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
        console.error("Failed to submit data");
      }
    } catch (error) {
      enqueueSnackbar("Error submitting data", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleNextClick = () => {
    setShowCard(true);
  };

  const handleCloseCard = () => {
    setShowCard(false);
  };

  const handleAddFundsChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 7) {
      setAddFunds(value);
    }
    if (value !== "") {
      setWithdrawFunds("");
    }
  };

  const handleWithdrawFundsChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    setWithdrawFunds(value);
    if (value !== "") {
      setAddFunds("");
    }
  };

  const handleAddFundsBlur = () => {
    if (addFunds !== "" && parseInt(addFunds, 10) < 5000) {
      enqueueSnackbar(
        "Minimum add funds value is 5000. Anything less will be considered as 0.",
        {
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        }
      );
      setAddFunds("");
    }
  };

  const handleWithdrawFundsBlur = () => {
    const maxWithdraw = currentHoldingValue * 0.5;
    const minWithdraw = 1000;
    const withdrawValue = parseInt(withdrawFunds, 10);

    if (withdrawFunds !== "" && withdrawValue > maxWithdraw) {
      enqueueSnackbar(
        `Maximum withdrawable amount is 50% of current holding value (${maxWithdraw.toFixed(
          2
        )}).`,
        {
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        }
      );
      setWithdrawFunds(maxWithdraw.toFixed(1));
    } else if (withdrawFunds !== "" && withdrawValue < minWithdraw) {
      enqueueSnackbar(
        `Minimum withdrawable amount is ${minWithdraw}. Anything less will be considered as 0.`,
        {
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        }
      );
      setWithdrawFunds("");
    }
  };

  const handleVarStatusChange = (event) => {
    setVarStatus(event.target.value === "yes" ? 1 : 0);
  };

  useEffect(() => {
    console.log("Closing Prices: ", closingPrices);
  }, [closingPrices]);

  return (
    <div className="mylist">
      <div className="mylist-header">
        <h2 className="mylist-h2">My List</h2>
        <img
          src={refreshImage}
          alt="Refresh"
          className="mylist-refresh-icon"
          onClick={handleResetQuantities}
        />
      </div>
      <div className="mylist-table">
        <table>
          <thead>
            <tr>
              <th>Scrip Symbol</th>
              <th>Units</th>
            </tr>
          </thead>
          <tbody>
            {scrips.map((scrip, index) => (
              <tr key={index}>
                <td
                  className={
                    scrip === "Scrip Symbol" ? "mylist-default-scrip" : ""
                  }
                >
                  {scrip}
                </td>
                <td>
                  <input
                    type="text"
                    value={quantities[index] !== null ? quantities[index] : ""}
                    onChange={(event) => handleInputChange(index, event)}
                    onInput={handleInputValidation}
                    onKeyDown={(event) => handleKeyDown(index, event)}
                    className={`mylist-unit-input ${
                      !scrip ? "mylist-disabled-input" : ""
                    }`}
                    disabled={!scrip}
                    placeholder={unitPlaceholders[index]}
                    ref={(el) => (inputRefs.current[index] = el)} // Assign refs
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mylist-optimise-section">
        <div className="mylist-optimise-heading">
          <span>Optimise data for Analysis</span>
        </div>
        <div className="mylist-optimise-content">
          <p className="mylist-description">
            (Minimum Variance or Minimum loss when portfolio goes negative)
          </p>
          <div className="mylist-expected-return">
            <input
              type="checkbox"
              id="goalCheckbox"
              className="mylist-goal-checkbox"
              checked
              readOnly
            />
            <label
              htmlFor="goalCheckbox"
              className="mylist-span mylist-disabled-label"
            >
              Set Goal for minimum Variance
            </label>
          </div>
        </div>
        <hr className="mylist-divider" />
        <button
          className="mylist-next-button"
          onClick={handleNextClick}
          disabled={nextDisabled}
        >
          {loading && !showCard ? (
            <TailSpin color="#00BFFF" height={20} width={20} />
          ) : (
            "NEXT"
          )}
        </button>
      </div>
      {showCard && (
        <div className="mylist-card">
          <div className="mylist-card-content">
            <button className="mylist-close-button" onClick={handleCloseCard}>
              ×
            </button>
            <h2 className="mylist-card-h2">Add/Withdraw Funds</h2>
            <div className="mylist-input-container">
              <div className="mylist-input-row">
                <label className="mylist-label">Current Holding Value</label>
                <input
                  type="text"
                  className="mylist-input"
                  value={currentHoldingValue}
                  readOnly
                />
              </div>
              <div className="mylist-input-row">
                <label className="mylist-label">Add Funds</label>
                <input
                  type="text"
                  className="mylist-input"
                  value={addFunds}
                  onChange={handleAddFundsChange}
                  onBlur={handleAddFundsBlur}
                  maxLength={7}
                  placeholder={addFundsPlaceholder}
                />
              </div>
              <div className="mylist-input-row">
                <label className="mylist-label">Withdraw Funds</label>
                <input
                  type="text"
                  className="mylist-input"
                  value={withdrawFunds}
                  onChange={handleWithdrawFundsChange}
                  onBlur={handleWithdrawFundsBlur}
                  disabled={withdrawDisabled}
                  placeholder={withdrawFundsPlaceholder}
                />
              </div>
              {withdrawDisabled && (
                <div className="mylist-warning-message">
                  Withdraw is disabled as one or more scrips are empty.
                </div>
              )}
            </div>
            <div className="mylist-notes-container">
              <div className="mylist-notes-label">
               
              </div>
              <div className="mylist-notes">
                <p className="point-one">
                <strong>Note: </strong>
                  For optimization (existing returns with optimized risk) No
                  need to add Additional funds or Withdraw funds, leave as it is
                  and submit.
                </p>
                {/* <p className="point-two">
                  2. If combination of Large cap, Mid cap and Small cap
                  portfolios exists Additional Fund is distributed to minimize
                  risk, Withdrawal is done without altering current proportion.
                </p>
                <p className="point-three">
                  3. Minimum value that can be entered in Add funds/Withdraw
                  funds is 1000. If both Add Funds/ Withdraw funds are entered,
                  then withdraw is rolled back to “0” before computing.
                </p> */}
              </div>
            </div>
            <div className="mylist-vartest">
              <h2 className="mylist-vartest-h2">
                Var (Value at Risk) and Stress Test (Crash Test)
              </h2>
              <div className="mylist-vartest-container">
                {/* <div className="mylist-vartest-checkbox-group">
                  <label>
                    <input
                      type="radio"
                      name="testOption"
                      value="yes"
                      onChange={handleVarStatusChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="testOption"
                      value="no"
                      onChange={handleVarStatusChange}
                    />
                    No
                  </label>
                </div> */}
                <div className="mylist-vartest-checkbox-group">
  <label className="mylist-radio-label">
    <input
      type="radio"
      name="testOption"
      value="yes"
      onChange={handleVarStatusChange}
      defaultChecked
    />
    Yes
  </label>
  <label className="mylist-radio-label">
    <input
      type="radio"
      name="testOption"
      value="no"
      onChange={handleVarStatusChange}
    />
    No
  </label>
</div>
                <p className="mylist-vartest-note">
                <strong>Note: </strong>
                 Both optimization and Stress Test subscription fee is
                  295/- INR for one submission. Only Optimization without Var
                  and stress test is 99/- INR for one submission.
                </p>
                {/* <p className="mylist-vartest-note">
                  2. VaR test (Value at Risk) gives user how much money will be
                  lost if portfolio undergoes correction.
                </p>
                <p className="mylist-vartest-note">
                  3. Stress test gives User how much money will be lost if
                  portfolio crashes due to systemic events.
                </p>
                <p className="mylist-vartest-note">
                  4. User also gets Star rating for portfolio. If star rating is
                  below 4, user has to add more equities and diversify
                </p> */}
              </div>
            </div>

            <div className="mylist-checkbox-container">
              <input
                type="checkbox"
                id="verifyCheckbox"
                className="mylist-verify-checkbox"
                checked={verifyChecked}
                onChange={() => setVerifyChecked(!verifyChecked)}
              />
              <label
                htmlFor="verifyCheckbox"
                className="mylist-verify-checkbox-label"
              >
                Verify that you're not a robot
              </label>
            </div>
            <div className="mylist-submit-button-container">
              <button
                className="mylist-submit-button"
                onClick={submit}
                disabled={!verifyChecked}
              >
                {loading && showCard ? (
                  <TailSpin color="#00BFFF" height={20} width={20} />
                ) : (
                  "SUBMIT"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyList;
