import React, { useState, useEffect, useRef } from "react";
import downArrow from "../../assets/down-arrow.png"; // Importing down arrow icon
import "./CustomDropdown.css"; // Import the CSS file

const CustomDropdown = ({
  options = [],
  selectedValue,
  onChange,
  placeholder,
  disabledOptions = [],
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Filter options based on the full search query from the beginning
  const filteredOptions = options.filter((option) =>
    option.toLowerCase().startsWith(searchQuery.toLowerCase())
  );

  const handleSelect = (value) => {
    onChange(value);
    setIsOpen(false);
    setSearchQuery(""); // Clear search query on selection
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value === "") {
      onChange(""); // Clear the selected value when the input is cleared
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && searchQuery === "") {
      onChange(""); // Clear the selected value when Backspace is pressed with an empty input
    }
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <input
        type="text"
        value={searchQuery || selectedValue}
        placeholder={placeholder}
        onClick={() => setIsOpen(!isOpen)}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <img
        src={downArrow}
        alt="Dropdown Arrow"
        className="dropdown-arrow"
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="dropdown-options">
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className={`dropdown-option ${
                disabledOptions.includes(option) ? "disabled" : ""
              }`}
              onClick={() =>
                !disabledOptions.includes(option) && handleSelect(option)
              }
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
