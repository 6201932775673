import React from "react";
import "./CancellationRefundPolicy.css";
import logo from "../../../assets/Ants-logo.png"; // Update the path as needed

const CancellationRefundPolicy = () => {
  return (
    <div className="policy-container">
      <img src={logo} alt="Company Logo" className="policy-logo" />
      <h1 className="policy-title">Cancellation and Refund Policy</h1>
      <p className="policy-text">
        This cancellation policy outlines how you can cancel or seek a refund for a product/service that you have purchased through the Platform.
      </p>
      <p className="policy-text">
        Under this policy:
      </p>
      <ul className="policy-list">
        <li className="policy-item">Cancellations will only be considered if the request is made within 2 calendar days of placing the order and still not availed the services. However, cancellation requests may not be entertained if the orders have been executed and the relevant report sent to the registered email id.</li>
        <li className="policy-item">Ants Applied Data Science Private Limited does not accept cancellation requests if services have been delivered except in cases where deficiency of service is reported within 72 hours of delivery of services.</li>
        <li className="policy-item">In case of receipt of deficiency or shortcomings in service, please report to our customer service team. The request would be entertained once the seller/merchant listed on the Platform has checked and determined the same at its own end. This should be reported within 3 days of receipt of service.</li>
        <li className="policy-item">In case you feel that the service received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 3 days of receiving the service. The customer service team, after looking into your complaint, will take an appropriate decision.</li>
        <li className="policy-item">In case of any refunds approved by Ants Applied Data Science Private Limited, it will take 7 working days for the refund to be processed.</li>
      </ul>
      <p className="policy-text">
        Ants Applied DataScience Private Limited.
      </p>
    </div>
  );
};

export default CancellationRefundPolicy;
