import React, { useState, useEffect, useRef } from "react";
import "./SignUp.css"; // Ensure you create this CSS file for styling
import AntsAi from "../assets/Ants.png"; // Ensure you have the logo image
import Img1 from "../assets/ApA2.png"; // Ensure you have the logo image
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { TailSpin } from "react-loader-spinner"; // Import the loader component
import LazyLoad from "react-lazyload";

const SignUp = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(60);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [verifyChecked, setVerifyChecked] = useState(false); // State for verify checkbox
  const [isSubmitting, setIsSubmitting] = useState(false); // State for loading
  const navigate = useNavigate();

  // References for OTP input fields
  const otpRefs = useRef([]);
  const otpModalRef = useRef(null);

  useEffect(() => {
    if (isOtpSent && timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isOtpSent, timer]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Backspace") {
        const index = otpRefs.current.findIndex(
          (ref) => ref === document.activeElement
        );
        if (index > 0 && otp[index] === "") {
          otpRefs.current[index - 1].focus();
        }
      }
    };

    const handleClickOutside = (event) => {
      if (
        otpModalRef.current &&
        !otpModalRef.current.contains(event.target) &&
        !event.target.closest(".SnackbarContainer-top-center")
      ) {
        setIsOtpSent(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [otp, isOtpSent]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    if (!verifyChecked) {
      window.alert("Please verify that you're not a robot.");
      return;
    }

    setIsSubmitting(true); // Set loading state to true

    const checkUserExistsData = {
      email_id: email,
    };

    try {
      // Check if user exists
      const checkResponse = await fetch(
        "https://api.antsanalyzer.com/check-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(checkUserExistsData),
        }
      );

      if (checkResponse.ok) {
        const checkData = await checkResponse.json();

        if (checkData.message === "User already exists") {
          enqueueSnackbar("User already exists. Please log in.", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
            autoHideDuration: 2000,
          });
          setIsSubmitting(false); // Set loading state to false
          navigate("/signin");
          return;
        } else {
          // If user does not exist, send OTP
          const sendOtpData = {
            user_email: email,
          };

          const otpResponse = await fetch(
            "https://api.antsanalyzer.com/sendOtp",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(sendOtpData),
            }
          );

          if (otpResponse.ok) {
            const otpData = await otpResponse.json();
            console.log("OTP sent successfully:", otpData);
            setIsOtpSent(true);
            enqueueSnackbar("OTP sent to your email. Please verify.", {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "center" },
              autoHideDuration: 2000,
            });
          } else {
            const errorData = await otpResponse.json();
            enqueueSnackbar(
              errorData.message || "Failed to send OTP. Please try again.",
              {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                autoHideDuration: 2000,
              }
            );
          }
        }
      } else {
        const errorData = await checkResponse.json();
        enqueueSnackbar(
          errorData.message ||
            "Error checking user existence. Please try again.",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
            autoHideDuration: 2000,
          }
        );
      }
    } catch (error) {
      console.error("Error during user existence check:", error);
      enqueueSnackbar("Unexpected error, please try again later.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 2000,
      });
    } finally {
      setIsSubmitting(false); // Set loading state to false
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if a number is entered
      if (value !== "" && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    const otpData = {
      user_email: email,
      otp: otp.join(""),
    };

    try {
      const response = await fetch("https://api.antsanalyzer.com/verifyOtp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otpData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("OTP verified successfully:", data);
        setStep(2);
        enqueueSnackbar("OTP verified. Please complete your sign up.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 2000,
        });
      } else {
        const errorData = await response.json();
        enqueueSnackbar(
          errorData.message || "OTP verification failed. Please try again.",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
            autoHideDuration: 2000,
          }
        );
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      enqueueSnackbar("Unexpected error, please try again later.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 2000,
      });
    }
  };

  const handleResendOtp = async () => {
    if (timer === 0) {
      setTimer(60);
      setIsOtpSent(true);

      const resendOtpData = {
        user_email: email,
      };

      try {
        const response = await fetch("https://api.antsanalyzer.com/sendOtp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(resendOtpData),
        });

        if (response.ok) {
          const data = await response.json();
          console.log("OTP resent successfully:", data);
          enqueueSnackbar("OTP resent successfully. Please check your email.", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
            autoHideDuration: 2000,
          });
        } else {
          const errorData = await response.json();
          enqueueSnackbar(
            errorData.message || "Failed to resend OTP. Please try again.",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "center" },
              autoHideDuration: 2000,
            }
          );
        }
      } catch (error) {
        console.error("Error during OTP resend:", error);
        enqueueSnackbar("Unexpected error, please try again later.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 2000,
        });
      }
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();

    if (!verifyChecked) {
      alert("Please verify that you're not a robot.");
      return;
    }

    setIsSubmitting(true); // Set loading state to true

    const signUpData = {
      user_email: email,
      first_name: firstName,
      last_name: lastName,
      mobile_no: phoneNumber,
      password: password,
      otp_status: true,
    };

    try {
      const response = await fetch("https://api.antsanalyzer.com/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signUpData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Sign Up successful:", data);
        enqueueSnackbar("Sign Up successful. You can now log in.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 2000,
        });
        navigate("/signin");
      } else {
        const errorData = await response.json();
        enqueueSnackbar(
          errorData.message || "Sign Up failed. Please try again.",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
            autoHideDuration: 2000,
          }
        );
      }
    } catch (error) {
      console.error("Error during Sign Up:", error);
      enqueueSnackbar("Unexpected error, please try again later.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 2000,
      });
    } finally {
      setIsSubmitting(false); // Set loading state to false
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-left">
        <LazyLoad height={200} offset={100}>
          <img src={Img1} alt="Img" className="signup-leftlogo1" />
        </LazyLoad>
      </div>
      <div className="signup-right">
        {step === 1 && (
          <form className="signup-form" onSubmit={handleEmailSubmit}>
            <img src={AntsAi} alt="AntsAI" className="signup-logo" />
            <h2 className="signup-h2">Sign Up</h2>
            <div className="signup-input-group1">
              <label>
                Email<span className="signup-mandatory">*</span>
              </label>
              <input
                className="input1"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="signup-checkbox-container">
                <input
                  type="checkbox"
                  id="verifyCheckbox"
                  className="signup-verify-checkbox"
                  checked={verifyChecked}
                  onChange={() => setVerifyChecked(!verifyChecked)}
                />
                <label
                  htmlFor="verifyCheckbox"
                  className="signup-verify-checkbox-label"
                >
                  Verify that you're not a robot
                </label>
              </div>
              <button
                type="submit"
                className="signup-submit-button"
                disabled={!verifyChecked || isSubmitting}
              >
                {isSubmitting ? (
                  <TailSpin height="20" width="20" color="#fff" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
            <div className="signup-forgot-password">
              <Link to="/Signin">Already have an account? Log in</Link>
            </div>
          </form>
        )}

        {isOtpSent && step === 1 && (
          <div className="signup-otp-modal" ref={otpModalRef}>
            <div className="signup-otp-modal-content">
              <button
                className="signup-otp-close-button"
                onClick={() => setIsOtpSent(false)}
              >
                &times;
              </button>
              <h3 className="signup-h3">Enter OTP</h3>
              <form className="signup-form" onSubmit={handleOtpSubmit}>
                <div className="signup-otp-inputs">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      maxLength="1"
                      required
                      ref={(el) => (otpRefs.current[index] = el)}
                    />
                  ))}
                </div>
                <div className="signup-resend-otp">
                  <span
                    onClick={handleResendOtp}
                    className={timer === 0 ? "resend-link" : "disabled"}
                  >
                    Resend OTP
                  </span>
                  <span>{timer}s</span>
                </div>
                <button type="submit" className="signup-submit-button">
                  Verify OTP
                </button>
              </form>
            </div>
          </div>
        )}

        {step === 2 && (
          <form className="signup-form" onSubmit={handleSignUpSubmit}>
            <img src={AntsAi} alt="AntsAI" className="signup-logo" />
            <h2 className="signup-h2">Complete Sign Up</h2>
            <div className="signup-input-group">
              <label>
                First Name<span className="signup-mandatory">*</span>
              </label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="signup-input-group">
              <label>
                Last Name<span className="signup-mandatory">*</span>
              </label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="signup-input-group">
              <label>
                Phone Number<span className="signup-mandatory">*</span>
              </label>
              <input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required
                maxLength="10"
              />
            </div>
            <div className="signup-input-group">
              <label>
                Password<span className="signup-mandatory">*</span>
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="signup-button">
              {isSubmitting ? (
                <TailSpin height="20" width="20" color="#fff" />
              ) : (
                "Sign Up"
              )}
            </button>
            <div className="signup-forgot-password">
              <Link to="/Signin">Go back</Link>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignUp;
