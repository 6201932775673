import React, { createContext, useState } from "react";

export const ScripContext = createContext();

export const ScripProvider = ({ children }) => {
  const [scrips, setScrips] = useState(Array(12).fill("Scrip Name"));
  const [quantities, setQuantities] = useState(Array(12).fill(null));
  const [selectedIndices, setSelectedIndices] = useState(Array(12).fill(""));
  const [closingPrices, setClosingPrices] = useState(Array(12).fill("-")); // Add closing prices

  return (
    <ScripContext.Provider
      value={{
        scrips,
        setScrips,
        quantities,
        setQuantities,
        selectedIndices,
        setSelectedIndices,
        closingPrices, // Provide closing prices
        setClosingPrices, // Provide setter for closing prices
      }}
    >
      {children}
    </ScripContext.Provider>
  );
};
