// import React, { useState, useEffect } from "react";
// import "./Report.css";
// import DownloadIcon from "../../assets/download.png";
// import AntsLogo from "../../assets/Ants-logo.png";
// import InfoIcon from "../../assets/information.svg";
// import Navbar from "../../Dashboard/navbar/Navbar";
// import { useNavigate } from "react-router-dom";
// import { useSnackbar } from "notistack";

// const Report = () => {
//   const [selectedReports, setSelectedReports] = useState([]);
//   const [latestReports, setLatestReports] = useState([]);
//   const [varReports, setVarReports] = useState([]);
//   const [notifications, setNotifications] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const token = localStorage.getItem("token");
//   const email = localStorage.getItem("user_email");
//   const navigate = useNavigate();
//   const { enqueueSnackbar } = useSnackbar();

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("user_email");
//     enqueueSnackbar("Unauthorized access, Please login", {
//       variant: "error",
//       anchorOrigin: {
//         vertical: "top",
//         horizontal: "center",
//       },
//     });
//     navigate("/signin");
//   };

//   const checkResponseStatus = (response) => {
//     console.log(`Response status: ${response.status}`);
//     if (response.status === 403) {
//       handleLogout();
//     }
//   };

//   useEffect(() => {
//     const fetchReports = async () => {
//       try {
//         console.log("Fetching regular reports...");
//         const response = await fetch(
//           "https://api.antsanalyzer.com/get-reportid",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//             body: JSON.stringify({ email_id: email }),
//           }
//         );

//         checkResponseStatus(response);

//         if (response.ok) {
//           const data = await response.json();
//           console.log("Regular reports data:", data);

//           // Regular Reports
//           const reports = data.reportDetails.map((report) => ({
//             title: "Analyzer Report",
//             date:
//               report.report_time !== "Invalid date"
//                 ? report.report_time
//                 : "0000-00-00 00:00:00",
//             reportID: report.report_id,
//           }));
//           const sortedReports = reports
//             .slice()
//             .sort((a, b) => new Date(b.date) - new Date(a.date));
//           setLatestReports(sortedReports.slice(0, 5));

//           console.log("Latest regular reports:", sortedReports.slice(0, 5));
//         } else {
//           console.error("Failed to fetch regular reports");
//         }
//       } catch (error) {
//         console.error("Error fetching regular reports:", error);
//       }
//     };

//     const fetchVarReports = async () => {
//       try {
//         console.log("Fetching VAR reports...");
//         const response = await fetch(
//           "https://api.antsanalyzer.com/var-report-id",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//             body: JSON.stringify({ email_id: email }),
//           }
//         );

//         checkResponseStatus(response);

//         if (response.ok) {
//           const data = await response.json();
//           console.log("VAR reports data:", data);

//           if (data.reportDetails && data.reportDetails.length > 0) {
//             const varReportsData = data.reportDetails.map((report) => ({
//               title: "VAR Report",
//               date:
//                 report.report_time !== "Invalid date"
//                   ? report.report_time
//                   : "0000-00-00 00:00:00",
//               varReportID: report.report_id,
//             }));
//             const sortedVarReports = varReportsData
//               .slice()
//               .sort((a, b) => new Date(b.date) - new Date(a.date));
//             setVarReports(sortedVarReports.slice(0, 5));

//             console.log("Latest VAR reports:", sortedVarReports.slice(0, 5));
//           } else {
//             console.error("No VAR reports found");
//           }
//         } else {
//           console.error("Failed to fetch VAR reports");
//         }
//       } catch (error) {
//         console.error("Error fetching VAR reports:", error);
//       }
//     };

//     fetchReports();
//     fetchVarReports();
//   }, [token, email]);

//   const addNotification = (message, time) => {
//     setNotifications((prevNotifications) => [
//       ...prevNotifications,
//       { id: prevNotifications.length + 1, message, time, isChecked: false },
//     ]);
//     console.log("New notification added:", message, time);
//   };

//   const handleSelectReport = (reportID) => {
//     setSelectedReports((prev) =>
//       prev.includes(reportID)
//         ? prev.filter((id) => id !== reportID)
//         : [...prev, reportID]
//     );
//     console.log("Selected reports:", selectedReports);
//   };

//   const handleDownloadSelected = async () => {
//     try {
//       console.log("Starting report download process...");
  
//       // Check if at least one report is selected
//       if (selectedReports.length === 0) {
//         enqueueSnackbar("Please select at least one report to download", {
//           variant: "error",
//           anchorOrigin: {
//             vertical: "top",
//             horizontal: "center",
//           },
//         });
//         return;
//       }
  
//       // Loop through all selected reports
//       for (const reportID of selectedReports) {
//         let apiEndpoint;
//         let requestBody;
  
//         // Check if the selected report is a VAR report
//         const isVarReport = varReports.some(
//           (report) => report.varReportID === reportID
//         );
  
//         if (isVarReport) {
//           // If it's a VAR report, set the VAR report API endpoint and body
//           apiEndpoint = "https://api.antsanalyzer.com/var-report";
//           const selectedVarReport = varReports.find(
//             (report) => report.varReportID === reportID
//           );
//           requestBody = JSON.stringify({
//             email_id: email,
//             var_report_id: selectedVarReport.varReportID,
//           });
//           console.log(`Downloading VAR report ${selectedVarReport.varReportID}`);
//         } else {
//           // Otherwise, it's an Analyzer report
//           apiEndpoint = "https://api.antsanalyzer.com/get-reports";
//           const selectedAnalyzerReport = latestReports.find(
//             (report) => report.reportID === reportID
//           );
//           requestBody = JSON.stringify({
//             email_id: email,
//             report_id: selectedAnalyzerReport.reportID,
//           });
//           console.log(
//             `Downloading Analyzer report ${selectedAnalyzerReport.reportID}`
//           );
//         }
  
//         // Fetch the report
//         const response = await fetch(apiEndpoint, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           body: requestBody,
//         });
  
//         checkResponseStatus(response);
  
//         if (response.ok) {
//           const blob = await response.blob();
//           const url = window.URL.createObjectURL(blob);
  
//           // Create an anchor element to trigger the download
//           const a = document.createElement("a");
//           a.href = url;
//           a.download = `${reportID}.pdf`;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a);
  
//           console.log(`Report ${reportID} downloaded successfully.`);
//         } else {
//           console.error(`Failed to download report ${reportID}`);
//           enqueueSnackbar(`Failed to download report ${reportID}`, {
//             variant: "error",
//             anchorOrigin: {
//               vertical: "top",
//               horizontal: "center",
//             },
//           });
//         }
//       }
  
//       enqueueSnackbar("All selected reports have been downloaded", {
//         variant: "success",
//         anchorOrigin: {
//           vertical: "top",
//           horizontal: "center",
//         },
//       });
//     } catch (error) {
//       console.error("Error downloading reports:", error);
//       enqueueSnackbar("Error downloading reports", {
//         variant: "error",
//         anchorOrigin: {
//           vertical: "top",
//           horizontal: "center",
//         },
//       });
//     }
//   };
  

//   const handleInfoClick = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div className="report-app">
//       <Navbar />
//       <div className="report-page">
//         <div className="report-header">
//           <h1 className="report-title">
//             Reports
//             <img
//               src={InfoIcon}
//               alt="Information"
//               className="report-info-icon"
//               onClick={handleInfoClick}
//             />
//           </h1>
//           <button
//             className="report-download-button"
//             onClick={handleDownloadSelected}
//           >
//             <img src={DownloadIcon} alt="Download" />
//             Download Selected
//           </button>
//         </div>

//         {/* Analyzer Reports Section */}
//         {latestReports.length > 0 ? (
//           <div className="report-container">
//             {latestReports.map((report) => (
//               <div className="report-card" key={report.reportID}>
//                 <h2 className="report-h2">{report.title}</h2>
//                 <p>{report.date}</p>
//                 <p>{report.reportID}</p>
//                 <label className="report-checkbox-container">
//                   <input
//                     type="checkbox"
//                     checked={selectedReports.includes(report.reportID)}
//                     onChange={() => handleSelectReport(report.reportID)}
//                   />
//                   <span className="report-checkmark"></span>
//                 </label>
//                 <img
//                   src={AntsLogo}
//                   alt="Ants Logo"
//                   className="report-card-logo"
//                 />
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="report-no-reports">Yet no reports to download</div>
//         )}

//         {/* Divider */}
//         <div className="report-divider"></div>

//         {/* VAR Reports Section */}
//         <div className="report-header">
//           <h1 className="report-title">
//             VAR Reports
//             <img
//               src={InfoIcon}
//               alt="Information"
//               className="report-info-icon"
//               onClick={handleInfoClick}
//             />
//           </h1>
//         </div>
//         {varReports.length > 0 ? (
//           <div className="report-container">
//             {varReports.map((report) => (
//               <div className="report-card" key={report.varReportID}>
//                 <h2 className="report-h2">{report.title}</h2>
//                 <p>{report.date}</p>
//                 <p>{report.varReportID}</p>
//                 <label className="report-checkbox-container">
//                   <input
//                     type="checkbox"
//                     checked={selectedReports.includes(report.varReportID)}
//                     onChange={() => handleSelectReport(report.varReportID)}
//                   />
//                   <span className="report-checkmark"></span>
//                 </label>
//                 <img
//                   src={AntsLogo}
//                   alt="Ants Logo"
//                   className="report-card-logo"
//                 />
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="report-no-reports">Yet no VAR reports to download</div>
//         )}

//         {isModalOpen && (
//           <div className="report-modal-overlay">
//             <div className="report-modal-content">
//               <h2 className="report-h2">Report Information</h2>
//               <p className="report-p">
//                 Please find your attached Analyzer Report. The password to
//                 access the report is constructed by taking the first four
//                 letters of your first name (in uppercase) followed by the last
//                 four digits of your mobile number. For example, if your name is
//                 Adam and your mobile number is 9876543210, your password will be
//                 ADAM3210.
//               </p>
//               <button
//                 className="report-modal-close-button"
//                 onClick={handleCloseModal}
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Report;


import React, { useState, useEffect } from "react";
import "./Report.css";
import DownloadIcon from "../../assets/download.png";
import AntsLogo from "../../assets/Ants-logo.png";
import InfoIcon from "../../assets/information.svg";
import Navbar from "../../Dashboard/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const Report = () => {
  const [selectedReports, setSelectedReports] = useState([]);
  const [latestReports, setLatestReports] = useState([]);
  const [varReports, setVarReports] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("user_email");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_email");
    enqueueSnackbar("Unauthorized access, Please login", {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
    navigate("/signin");
  };

  const checkResponseStatus = (response) => {
    console.log(`Response status: ${response.status}`);
    if (response.status === 403) {
      handleLogout();
    }
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        console.log("Fetching regular reports...");
        const response = await fetch(
          "https://api.antsanalyzer.com/get-reportid",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ email_id: email }),
          }
        );

        checkResponseStatus(response);

        if (response.ok) {
          const data = await response.json();
          console.log("Regular reports data:", data);

          const reports = data.reportDetails.map((report) => ({
            title: "Analyzer Report",
            date:
              report.report_time !== "Invalid date"
                ? report.report_time
                : "0000-00-00 00:00:00",
            reportID: report.report_id,
          }));
          const sortedReports = reports
            .slice()
            .sort((a, b) => new Date(b.date) - new Date(a.date));
          setLatestReports(sortedReports.slice(0, 5));

          console.log("Latest regular reports:", sortedReports.slice(0, 5));
        } else {
          console.error("Failed to fetch regular reports");
        }
      } catch (error) {
        console.error("Error fetching regular reports:", error);
      }
    };

    const fetchVarReports = async () => {
      try {
        console.log("Fetching VAR reports...");
        const response = await fetch(
          "https://api.antsanalyzer.com/var-report-id",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ email_id: email }),
          }
        );

        checkResponseStatus(response);

        if (response.ok) {
          const data = await response.json();
          console.log("VAR reports data:", data);

          if (data.reportDetails && data.reportDetails.length > 0) {
            const varReportsData = data.reportDetails.map((report) => ({
              title: "VAR Report",
              date:
                report.report_time !== "Invalid date"
                  ? report.report_time
                  : "0000-00-00 00:00:00",
              varReportID: report.report_id,
            }));
            const sortedVarReports = varReportsData
              .slice()
              .sort((a, b) => new Date(b.date) - new Date(a.date));
            setVarReports(sortedVarReports.slice(0, 5));

            console.log("Latest VAR reports:", sortedVarReports.slice(0, 5));
          } else {
            console.error("No VAR reports found");
          }
        } else {
          console.error("Failed to fetch VAR reports");
        }
      } catch (error) {
        console.error("Error fetching VAR reports:", error);
      }
    };

    fetchReports();
    fetchVarReports();
  }, [token, email]);

  const addNotification = (message, time) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id: prevNotifications.length + 1, message, time, isChecked: false },
    ]);
    console.log("New notification added:", message, time);
  };

  const handleSelectReport = (reportID) => {
    setSelectedReports((prev) =>
      prev.includes(reportID)
        ? prev.filter((id) => id !== reportID)
        : [...prev, reportID]
    );
    console.log("Selected reports:", selectedReports);
  };

  const handleDownloadSelected = async () => {
    try {
      console.log("Starting report download process...");
  
      // Check if at least one report is selected
      if (selectedReports.length === 0) {
        enqueueSnackbar("Please select at least one report to download", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        return;
      }
  
      // Loop through all selected reports
      for (const reportID of selectedReports) {
        let apiEndpoint;
        let requestBody;
  
        // Check if the selected report is a VAR report
        const isVarReport = varReports.some(
          (report) => report.varReportID === reportID
        );
  
        if (isVarReport) {
          apiEndpoint = "https://api.antsanalyzer.com/var-report";
          const selectedVarReport = varReports.find(
            (report) => report.varReportID === reportID
          );
  
          // Ensure both email and var_report_id are sent in the body
          requestBody = JSON.stringify({
            email_id: email,
            report_id: selectedVarReport.varReportID, // Correctly pass the VAR report ID
          });
  
          console.log(`Downloading VAR report ${selectedVarReport.varReportID}`);
        } else {
          apiEndpoint = "https://api.antsanalyzer.com/get-reports";
          const selectedAnalyzerReport = latestReports.find(
            (report) => report.reportID === reportID
          );
  
          // For Analyzer reports
          requestBody = JSON.stringify({
            email_id: email,
            report_id: selectedAnalyzerReport.reportID, // Pass the Analyzer report ID
          });
  
          console.log(
            `Downloading Analyzer report ${selectedAnalyzerReport.reportID}`
          );
        }
  
        // Log the request body for debugging
        console.log("Request body:", requestBody);
  
        // Fetch the report
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: requestBody,
        });
  
        checkResponseStatus(response);
  
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
  
          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = `${reportID}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
  
          console.log(`Report ${reportID} downloaded successfully.`);
        } else {
          // Log error response from the API
          const errorData = await response.json();
          console.error(`Failed to download report ${reportID}`, errorData);
          enqueueSnackbar(`Failed to download report ${reportID}`, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        }
      }
  
      enqueueSnackbar("All selected reports have been downloaded", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    } catch (error) {
      console.error("Error downloading reports:", error);
      enqueueSnackbar("Error downloading reports", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  };
  

  const handleInfoClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="report-app">
      <Navbar />
      <div className="report-page">
        <div className="report-header">
          <h1 className="report-title">
            Reports
            <img
              src={InfoIcon}
              alt="Information"
              className="report-info-icon"
              onClick={handleInfoClick}
            />
          </h1>
          <button
            className="report-download-button"
            onClick={handleDownloadSelected}
          >
            <img src={DownloadIcon} alt="Download" />
            Download Selected
          </button>
        </div>

        {/* Analyzer Reports Section */}
        {latestReports.length > 0 ? (
          <div className="report-container">
            {latestReports.map((report) => (
              <div className="report-card" key={report.reportID}>
                <h2 className="report-h2">{report.title}</h2>
                <p>{report.date}</p>
                <p>{report.reportID}</p>
                <label className="report-checkbox-container">
                  <input
                    type="checkbox"
                    checked={selectedReports.includes(report.reportID)}
                    onChange={() => handleSelectReport(report.reportID)}
                  />
                  <span className="report-checkmark"></span>
                </label>
                <img
                  src={AntsLogo}
                  alt="Ants Logo"
                  className="report-card-logo"
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="report-no-reports">Yet no reports to download</div>
        )}

        {/* Divider */}
        <div className="report-divider"></div>

        {/* VAR Reports Section */}
        <div className="report-header">
          <h1 className="report-title">
            VAR Reports
            <img
              src={InfoIcon}
              alt="Information"
              className="report-info-icon"
              onClick={handleInfoClick}
            />
          </h1>
        </div>
        {varReports.length > 0 ? (
          <div className="report-container">
            {varReports.map((report) => (
              <div className="report-card" key={report.varReportID}>
                <h2 className="report-h2">{report.title}</h2>
                <p>{report.date}</p>
                <p>{report.varReportID}</p>
                <label className="report-checkbox-container">
                  <input
                    type="checkbox"
                    checked={selectedReports.includes(report.varReportID)}
                    onChange={() => handleSelectReport(report.varReportID)}
                  />
                  <span className="report-checkmark"></span>
                </label>
                <img
                  src={AntsLogo}
                  alt="Ants Logo"
                  className="report-card-logo"
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="report-no-reports">Yet no VAR reports to download</div>
        )}

        {isModalOpen && (
          <div className="report-modal-overlay">
            <div className="report-modal-content">
              <h2 className="report-h2">Report Information</h2>
              <p className="report-p">
                Please find your attached Analyzer Report. The password to
                access the report is constructed by taking the first four
                letters of your first name (in uppercase) followed by the last
                four digits of your mobile number. For example, if your name is
                Adam and your mobile number is 9876543210, your password will be
                ADAM3210.
              </p>
              <button
                className="report-modal-close-button"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Report;
