import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./99PlanCard.css";
import PhonePeImage from "../../../assets/295qrcode.png"; // Import the image

const PlanCard = () => {
  return (
    <div className="plan-firstdiv">
      <h2 className="plan-h2">Payment</h2>
      <div className="card plan-card">
        <h2 className="subscription-h2">Scan to pay with any UPI app</h2>
        <LazyLoadImage
          src={PhonePeImage}
          alt="Payment Method"
          effect="blur"
          className="payment-image"
        />
        <a
          href={PhonePeImage}
          download="295qrcode.png"
          className="download-button"
        >
          Download QR Code
        </a>
      </div>
    </div>
  );
};

export default PlanCard;
