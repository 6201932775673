import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import "./Opt-SubscriptionCard.css";

const SubscriptionCard = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/report");
  };

  const handleSubmit = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("user_email");

    const requestData = {
      email_id: email,
      transaction_id: transactionId,
      payment_fee: 295.0,
    };

    try {
      const response = await fetch("https://api.antsanalyzer.com/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setLoading(false);
        setIsExpanded(false);
        enqueueSnackbar(
          "Payment process completed We will Verify your Transaction ID, please wait 24 hours to get your reports!",
          {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "left" },
            autoHideDuration: 4000,
          }
        );
        navigate("/report");
      } else {
        setLoading(false);
        alert("Failed to complete the payment process. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting payment:", error);
    }
  };

  const handleTransactionIdChange = (e) => {
    const value = e.target.value.toUpperCase();
    const regex = /^[A-Z0-9]{0,23}$/;

    if (regex.test(value)) {
      setTransactionId(value);
    }
  };

  return (
    <div className="subscription-firstdiv">
      <h2 className="subscriptioncard-h2">Subscription</h2>
      <div className="card subscription-card">
        {/* <p className="s1">
          You can submit 1 Portfolio in 24 hrs for next 30 Calendar days for a
          Fee of just <span>₹295/-</span> including GST.
        </p> */}
        <p className="subscription-highlight">Please read carefully:</p>
        {/* <p className="s1">
          Report will be sent to your Email ID in the next 24 to 48 hours. After
          making payment upload transaction details to{" "}
          <a href="mailto:contactus@antsai.in">contactus@antsai.in</a> and get
          subscription confirmation email in the next 24 hours.
        </p> */}

        <p className="s1">
          {" "}
          Your portfolio will be optimized and sent to your email_ID in next 1
          hour. Your subscription charges are 99/- INR (inclusive GST) for 1
          report generation.
        </p>
        <p className="transaction-note">
          After payment submit your transaction id below.
        </p>
        <div className="transaction-container">
          <input
            type="text"
            className="transaction-input"
            placeholder="Enter Transaction ID"
            value={transactionId}
            onChange={handleTransactionIdChange}
          />
          <button className="submit-transaction-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
        <div className="privacy-links">
          <p>
            By proceeding, you agree to our
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              {" "}
              Privacy Policy
            </a>
            ,
            <a
              href="/terms-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Terms & Conditions
            </a>{" "}
            and
            <a
              href="/cancellation-refund-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Cancellation & Refund Policy
            </a>
            .
          </p>
        </div>
      </div>
      {/* <div className="subscriptioncard-footer">
        <div className="subscriptioncard-footer-text">
          If already Subscribed
        </div>
        <button className="subscriptioncard-skip-button" onClick={handleCancel}>
          Skip
        </button>
      </div> */}
    </div>
  );
};

export default SubscriptionCard;
