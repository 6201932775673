import React, { useState, useEffect, useRef } from "react";
import "./ForgotPassword.css";
import AntsAi from "../assets/Ants.png";
import Img1 from "../assets/ApA2.png";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { TailSpin } from "react-loader-spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(60);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [verifyChecked, setVerifyChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const otpRefs = useRef([]);
  const otpModalRef = useRef(null);

  useEffect(() => {
    if (isOtpSent && timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isOtpSent, timer]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Backspace") {
        const index = otpRefs.current.findIndex(
          (ref) => ref === document.activeElement
        );
        if (index > 0 && otp[index] === "") {
          otpRefs.current[index - 1].focus();
        }
      }
    };

    const handleClickOutside = (event) => {
      if (
        otpModalRef.current &&
        !otpModalRef.current.contains(event.target) &&
        !event.target.closest(".SnackbarContainer-top-center")
      ) {
        setIsOtpSent(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [otp, isOtpSent]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    // Ensure at least one letter, one number, and at least 8 characters
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!]{8,}$/;
    return re.test(password);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      enqueueSnackbar("Invalid email format.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
      return;
    }
    setError("");
    setSuccess("");

    setIsSubmitting(true); // Set loading state to true

    const checkUserExistsData = {
      email_id: email,
    };

    try {
      // Check if user exists
      const checkResponse = await fetch(
        "https://api.antsanalyzer.com/check-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(checkUserExistsData),
        }
      );

      if (checkResponse.ok) {
        const checkData = await checkResponse.json();

        if (checkData.message === "User does not exist") {
          enqueueSnackbar("User does not exist.", {
            variant: "info",
            anchorOrigin: { vertical: "top", horizontal: "left" },
          });
          setIsSubmitting(false);
          return;
        } else {
          // If user exists, send OTP
          const sendOtpData = {
            user_email: email,
          };

          const otpResponse = await fetch(
            "https://api.antsanalyzer.com/sendOtp",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(sendOtpData),
            }
          );

          if (otpResponse.ok) {
            setIsOtpSent(true);
            enqueueSnackbar("OTP sent to your email. Please verify.", {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "left" },
            });
          } else {
            const errorData = await otpResponse.json();
            setError(
              errorData.message || "Failed to send OTP. Please try again."
            );
            enqueueSnackbar(
              errorData.message || "Failed to send OTP. Please try again.",
              {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "left" },
              }
            );
          }
        }
      } else {
        const errorData = await checkResponse.json();
        enqueueSnackbar(
          errorData.message ||
            "Error checking user existence. Please try again.",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "left" },
          }
        );
      }
    } catch (error) {
      console.error("Error during user existence check:", error);
      enqueueSnackbar("Unexpected error, please try again later.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
    } finally {
      setIsSubmitting(false); // Set loading state to false
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if a number is entered
      if (value !== "" && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    const otpData = {
      user_email: email,
      otp: otp.join(""),
    };

    try {
      const response = await fetch("https://api.antsanalyzer.com/verifyOtp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otpData),
      });

      if (response.ok) {
        setStep(2);
        enqueueSnackbar("OTP verified. Please reset your password.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
      } else {
        const errorData = await response.json();
        setError(
          errorData.message || "OTP verification failed. Please try again."
        );
        enqueueSnackbar(
          errorData.message || "OTP verification failed. Please try again.",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "left" },
          }
        );
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setError("An unexpected error occurred. Please try again.");
      enqueueSnackbar("An unexpected error occurred. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
    }
  };

  const handleResendOtp = async () => {
    if (timer === 0) {
      setTimer(60);
      setIsOtpSent(true);
      setError("");
      setSuccess("");
      const resendOtpData = {
        user_email: email,
      };

      try {
        const response = await fetch("https://api.antsanalyzer.com/sendOtp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(resendOtpData),
        });

        if (response.ok) {
          enqueueSnackbar("OTP resent successfully. Please check your email.", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "left" },
          });
        } else {
          const errorData = await response.json();
          setError(
            errorData.message || "Failed to resend OTP. Please try again."
          );
          enqueueSnackbar(
            errorData.message || "Failed to resend OTP. Please try again.",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "left" },
            }
          );
        }
      } catch (error) {
        console.error("Error during OTP resend:", error);
        setError("An unexpected error occurred. Please try again.");
        enqueueSnackbar("An unexpected error occurred. Please try again.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
      }
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    if (password !== confirmPassword) {
      setError("Passwords do not match. Please try again.");
      enqueueSnackbar("Passwords do not match. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
      return;
    }

    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters long and contain both letters and numbers."
      );
      enqueueSnackbar(
        "Password must be at least 8 characters long and contain both letters and numbers.",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        }
      );
      return;
    }

    const passwordData = {
      user_email: email,
      new_password: password,
    };

    try {
      const response = await fetch(
        "https://api.antsanalyzer.com/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passwordData),
        }
      );

      if (response.ok) {
        enqueueSnackbar("Password reset successful. You can now log in.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
        setSuccess("Password reset successful. You can now log in.");
        navigate("/signin");
      } else {
        const errorData = await response.json();
        setError(
          errorData.message || "Password reset failed. Please try again."
        );
        enqueueSnackbar(
          errorData.message || "Password reset failed. Please try again.",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "left" },
          }
        );
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      setError("An unexpected error occurred. Please try again.");
      enqueueSnackbar("An unexpected error occurred. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-left">
        <img src={Img1} alt="Img" className="forgot-password-leftlogo1" />
      </div>
      <div className="forgot-password-right">
        {step === 1 ? (
          <form className="forgot-password-form" onSubmit={handleEmailSubmit}>
            <img src={AntsAi} alt="AntsAI" className="forgot-password-logo" />
            <h2 className="forgot-password-h2">Forgot Password</h2>
            {error && <p className="forgot-password-error-message">{error}</p>}
            {success && (
              <p className="forgot-password-success-message">{success}</p>
            )}
            <div className="forgot-password-input-group">
              <label>
                Email<span className="forgot-mandatory">*</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="forgot-checkbox-container">
              <input
                type="checkbox"
                id="verifyCheckbox"
                className="forgot-verify-checkbox"
                checked={verifyChecked}
                onChange={() => setVerifyChecked(!verifyChecked)}
              />
              <label
                htmlFor="verifyCheckbox"
                className="forgot-verify-checkbox-label"
              >
                Verify that you're not a robot
              </label>
            </div>
            <button
              type="submit"
              className="forgot-password-submit-button"
              disabled={!verifyChecked || isSubmitting}
            >
              {isSubmitting ? (
                <TailSpin height="20" width="20" color="#fff" />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        ) : (
          <form
            className="forgot-password-form"
            onSubmit={handlePasswordSubmit}
          >
            <img src={AntsAi} alt="AntsAI" className="forgot-password-logo" />
            <h2>Reset Password</h2>
            {error && <p className="forgot-password-error-message">{error}</p>}
            {success && (
              <p className="forgot-password-success-message">{success}</p>
            )}
            <div className="forgot-password-input-group">
              <label>
                New Password<span className="forgot-mandatory">*</span>
              </label>
              <div className="password-field">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="toggle-password-visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            <div className="forgot-password-input-group">
              <label>
                Confirm Password<span className="forgot-mandatory">*</span>
              </label>
              <div className="password-field">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="toggle-password-visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            <button type="submit" className="forgot-password-submit-button">
              Reset Password
            </button>
          </form>
        )}
        <div className="forgot-password-back-to-signin">
          <Link to="/Signin">Back to Sign In</Link>
        </div>
      </div>

      {isOtpSent && step === 1 && (
        <div className="forgot-password-otp-modal">
          <div className="forgot-password-otp-modal-content" ref={otpModalRef}>
            <button
              className="forgot-password-otp-close-button"
              onClick={() => setIsOtpSent(false)}
            >
              &times;
            </button>
            <h3 className="forgot-password-h3">Enter OTP</h3>
            <form className="forgot-password-form" onSubmit={handleOtpSubmit}>
              <div className="forgot-password-otp-inputs">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    maxLength="1"
                    required
                    ref={(el) => (otpRefs.current[index] = el)}
                  />
                ))}
              </div>
              <div className="forgot-password-resend-otp">
                <span
                  onClick={handleResendOtp}
                  className={timer === 0 ? "resend-link" : "disabled"}
                >
                  Resend OTP
                </span>
                <span>{timer}s</span>
              </div>
              <button type="submit" className="forgot-password-submit-button">
                Verify OTP
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
