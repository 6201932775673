import React, { useState, useContext, useEffect } from "react";
import "./Stocks.css";
import arrowImage from "../../assets/arrow.svg";
import funnelIcon from "../../assets/funnel_icon.svg";
import closeIcon from "../../assets/close.svg";
import CustomDropdown from "./CustomDropdown";
import { ScripContext } from "../context/ScripContext"; // Import the context
import { useSnackbar } from "notistack"; // Import useSnackbar from notistack
import { TailSpin } from "react-loader-spinner"; // Import TailSpin loader

const Stocks = ({
  indexList,
  symbols,
  onGroupSelect,
  onScripChange,
  closingPrices,
  expectedReturns,
  variances,
  selectedHistory,
  selectedForecast,
  onHistoryChange,
  onForecastChange,
}) => {
  const {
    scrips,
    setScrips,
    quantities,
    setQuantities,
    selectedIndices,
    setSelectedIndices,
    setClosingPrices,
  } = useContext(ScripContext); // Use the context
  const { enqueueSnackbar } = useSnackbar(); // Initialize the snackbar

  const [selectedScrips, setSelectedScrips] = useState(Array(12).fill(""));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingDropdowns, setLoadingDropdowns] = useState(
    Array(12).fill(false)
  ); // Loading state for dropdowns

  const handleIndexChange = (index, i) => {
    setLoadingDropdown(i, true); // Start loading spinner for the dropdown
    const newSelectedIndices = [...selectedIndices];
    const newSelectedScrips = [...selectedScrips];

    newSelectedIndices[i] = index;
    newSelectedScrips[i] = ""; // Reset the selected symbol

    setSelectedIndices(newSelectedIndices);
    setSelectedScrips(newSelectedScrips);

    onGroupSelect(index, i);
    setLoadingDropdown(i, false); // Stop loading spinner for the dropdown
  };

  const handleScripChange = (scrip, i) => {
    setLoadingDropdown(i, true); // Start loading spinner for the dropdown
    const newSelectedScrips = [...selectedScrips];
    newSelectedScrips[i] = scrip;
    setSelectedScrips(newSelectedScrips);
    onScripChange(scrip, i);
    setLoadingDropdown(i, false); // Stop loading spinner for the dropdown
  };

  const setLoadingDropdown = (index, state) => {
    const newLoadingDropdowns = [...loadingDropdowns];
    newLoadingDropdowns[index] = state;
    setLoadingDropdowns(newLoadingDropdowns);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    const data = {
      select_history: selectedHistory,
      compute_duration: selectedForecast,
    };
    console.log(data);
  };

  const handleHistoryChange = (event) => {
    const value = parseFloat(event.target.value);
    onHistoryChange(value);
  };

  const handleForecastChange = (event) => {
    const value = parseInt(event.target.value, 10);
    onForecastChange(value);
  };

  const validateScrips = (scrips) => {
    const categories = ["Large Cap", "Mid Cap", "Small Cap"];
    for (const category of categories) {
      const scripsInCategory = scrips.filter(
        (scrip) => scrip.category === category
      );
      const nonEmptyScrips = scripsInCategory.filter(
        (scrip) => scrip.quantity !== null
      );
      const emptyScrips = scripsInCategory.filter(
        (scrip) => scrip.quantity === null
      );

      if (nonEmptyScrips.length === 2 && emptyScrips.length > 1) {
        return `For ${category}, you can only have up to two stocks with quantities and one empty quantity.`;
      }
      if (nonEmptyScrips.length > 2 && emptyScrips.length > 2) {
        return `For ${category}, you can only have up to two stocks with quantities and two empty quantities.`;
      }
    }
    return null;
  };

  const pushToList = () => {
    const selectedCount = selectedScrips.filter((scrip) => scrip !== "").length;
    const scripsToValidate = selectedScrips.map((scrip, index) => ({
      category: selectedIndices[index],
      quantity: quantities[index],
    }));
    const validationError = validateScrips(scripsToValidate);

    if (validationError) {
      enqueueSnackbar(validationError, {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
      return;
    }

    if (selectedCount < 2) {
      enqueueSnackbar("Please select at least 2 Scrip Symbols", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
    } else {
      setScrips(selectedScrips); // Update the scrips in the context
      setClosingPrices(closingPrices); // Update the closing prices in the context
      setQuantities(Array(12).fill(null)); // Reset quantities
      enqueueSnackbar("Scrips pushed to list", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "left" },
      });
    }
  };

  return (
    <div className="stocks">
      <div className="stocks-header">
        <h2 className="stocks-h2">Stocks</h2>
        <div className="funnel-icon-container">
          <img
            src={funnelIcon}
            alt="Funnel"
            className="funnel-icon"
            onClick={openModal}
          />
          <div className="tooltip">Time Period</div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={closeIcon}
                alt="Close"
                className="stocks-close-icon"
                onClick={closeModal}
              />
            </div>
            <div className="modal-body">
              <div className="modal-section">
                <h3 className="stocks-h3">
                  Select Historical Data for Analysis
                </h3>
                <div className="stocks-card">
                  <div className="stocks-radio-group-row">
                    <label>
                      <input
                        type="radio"
                        name="historical-data"
                        value={0.3}
                        checked={selectedHistory === 0.3}
                        onChange={handleHistoryChange}
                      />{" "}
                      3 Months
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="historical-data"
                        value={0.6}
                        checked={selectedHistory === 0.6}
                        onChange={handleHistoryChange}
                      />{" "}
                      6 Months
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="historical-data"
                        value={1}
                        checked={selectedHistory === 1}
                        onChange={handleHistoryChange}
                      />{" "}
                      1 Year
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="historical-data"
                        value={3}
                        checked={selectedHistory === 3}
                        onChange={handleHistoryChange}
                      />{" "}
                      3 Years
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-section">
                <h3 className="stocks-h3">Select Forecast Period</h3>
                <div className="stocks-card">
                  <div className="stocks-radio-group-row">
                    <label>
                      <input
                        type="radio"
                        name="forecast-period"
                        value={1}
                        checked={selectedForecast === 1}
                        onChange={handleForecastChange}
                      />{" "}
                      1 Year
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="forecast-period"
                        value={3}
                        checked={selectedForecast === 3}
                        onChange={handleForecastChange}
                      />{" "}
                      3 Years
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="apply-button-container">
              <button className="apply-button" onClick={closeModal}>
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="stocks-table-container">
        <div className="stocks-table">
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Scrip Symbol</th>
                <th className="right-align">Closing Price</th>
                <th className="right-align">Expected Return/INR/Mon</th>
                <th className="right-align">Standard Deviation</th>
              </tr>
            </thead>
            <tbody>
  {Array.from({ length: 12 }).map((_, i) => (
    <tr key={i}>
      <td className="dropdown-cell">
        {loadingDropdowns[i] ? (
          <TailSpin color="#00BFFF" height={20} width={20} />
        ) : (
          <CustomDropdown
            options={indexList}
            selectedValue={selectedIndices[i]}
            onChange={(value) => handleIndexChange(value, i)}
            placeholder="Select Index"
            onClick={() => setLoadingDropdown(i, true)}
            onDropdownLoad={() => setLoadingDropdown(i, false)}
          />
        )}
      </td>
      <td className="dropdown-cell">
        {loadingDropdowns[i] ? (
          <TailSpin color="#00BFFF" height={20} width={20} />
        ) : (
          <CustomDropdown
            options={symbols[i] || []}
            selectedValue={selectedScrips[i]}
            onChange={(value) => handleScripChange(value, i)}
            placeholder="Select Scrip"
            disabledOptions={selectedScrips.filter(
              (scrip, idx) => scrip !== "" && idx !== i
            )}
            onClick={() => setLoadingDropdown(i, true)}
            onDropdownLoad={() => setLoadingDropdown(i, false)}
          />
        )}
      </td>
      <td className="right-align">{closingPrices[i]}</td>
      <td className="right-align">{expectedReturns[i]}</td>
      <td className="right-align">
  {variances[i] !== null && variances[i] !== undefined && !isNaN(variances[i])
    ? Math.sqrt(variances[i]).toFixed(2)
    : "-"}
</td>
    </tr>
  ))}
</tbody>
            {/* <tbody>
              {Array.from({ length: 12 }).map((_, i) => (
                <tr key={i}>
                  <td className="dropdown-cell">
                    {loadingDropdowns[i] ? (
                      <TailSpin color="#00BFFF" height={20} width={20} />
                    ) : (
                      <CustomDropdown
                        options={indexList}
                        selectedValue={selectedIndices[i]}
                        onChange={(value) => handleIndexChange(value, i)}
                        placeholder="Select Index"
                        onClick={() => setLoadingDropdown(i, true)}
                        onDropdownLoad={() => setLoadingDropdown(i, false)}
                      />
                    )}
                  </td>
                  <td className="dropdown-cell">
                    {loadingDropdowns[i] ? (
                      <TailSpin color="#00BFFF" height={20} width={20} />
                    ) : (
                      <CustomDropdown
                        options={symbols[i] || []}
                        selectedValue={selectedScrips[i]}
                        onChange={(value) => handleScripChange(value, i)}
                        placeholder="Select Scrip"
                        disabledOptions={selectedScrips.filter(
                          (scrip, idx) => scrip !== "" && idx !== i
                        )}
                        onClick={() => setLoadingDropdown(i, true)}
                        onDropdownLoad={() => setLoadingDropdown(i, false)}
                      />
                    )}
                  </td>
                  <td className="right-align">{closingPrices[i]}</td>
                  <td className="right-align">{expectedReturns[i]}</td>
                  <td className="right-align">{variances[i]}</td>
                </tr>
              ))}
            </tbody> */}
          </table>
        </div>
      </div>
      <div className="push-button-container">
        <div className="push-button" onClick={pushToList}>
          <span>Push to List</span>
          <img src={arrowImage} alt="Arrow" className="action-arrow" />
        </div>
      </div>
    </div>
  );
};

export default Stocks;
