// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SignIn from "./Login/Signin";
import SignUp from "./Login/SignUp";
import ForgotPassword from "./Login/ForgotPassword";
import Analyser from "./Dashboard/analyser/Analyser";
import StressTest from "./Dashboard/stresstest/StressTest";
import OptSubscription from "./Dashboard/analyser/subscription/Opt-SubscriptionMain";
import StressTestSubscription from "./Dashboard/analyser/subscription/StressTest-SubscriptionMain";

import Report from "./Dashboard/report/Report";
import Profile from "./Dashboard/navbar/Profile"; // Import the Profile component
import PrivacyPolicy from "./Dashboard/analyser/subscription/PrivacyPolicy";
import TermsConditions from "./Dashboard/analyser/subscription/TermsConditions";
import CancellationRefundPolicy from "./Dashboard/analyser/subscription/CancellationRefundPolicy";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/analyser" element={<Analyser />} />
      <Route path="/stresstest" element={<StressTest />} />
      <Route path="/optsubscription" element={<OptSubscription />} />
      <Route
        path="/stresstestsubscription"
        element={<StressTestSubscription />}
      />
      <Route path="/report" element={<Report />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
      {/* Privacy Policy route */}
      <Route path="/terms-conditions" element={<TermsConditions />} />{" "}
      {/* Terms & Conditions route */}
      <Route
        path="/cancellation-refund-policy"
        element={<CancellationRefundPolicy />}
      />{" "}
      {/* CancellationRefundPolicy route */}
    </Routes>
  );
}
