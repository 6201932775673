import React from "react";
import "./Opt-SubscriptionMain.css";
import SubscriptionCard from "./Opt-SubscriptionCard";
import PlanCard from "./99PlanCard";
import Navbar from "../../navbar/Navbar";

const SubscriptionMain = () => {
  return (
    <div className="subscription">
      <Navbar />
      <div className="subscription-main">
        <div className="subscription-container">
          <PlanCard />
          <SubscriptionCard />
        </div>
      </div>
      <footer className="subscription-footer">
        <p className="subscription-footer-text">
          © 2024 Ants Applied Data Science Pvt Ltd. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default SubscriptionMain;
